import React from 'react';
import './styles/index.scss';
function FooterButtom(){
    return (
        <div className="footer-content">
            -&nbsp;&nbsp;&nbsp; 到底啦 &nbsp;&nbsp;&nbsp;-
        </div>
    )
};
export default FooterButtom;
