import React from "react";
import "./styles/index.scss";
import MobileModel from "../../../Components/MobileModel";
import appState from "../../../appStatus";
import {PickerView} from "antd-mobile";
import {observer, inject, Provider} from "mobx-react";
import {toJS} from "mobx";
import classnames from "classnames";
import {SearchRoomParmas} from "../../../appStatus/server";
import moment from "moment";
import PcTimeFilter from './components/PcTimeFilter';

interface HoomFilterStatus {
  visibility: boolean;
}

const memberNumberTag = [
  {val: "0-5", label: <span> 少于6人</span>},
  {val: "6-10", label: <span> 6 - 10 人 </span>},
  {val: "10-20", label: <span> 10 - 20 人 </span>},
  {val: "21-100", label: <span> 20人以上</span>},
];

@inject("store")
@observer
class HoomFilter extends React.Component<{store: any}, HoomFilterStatus> {
  constructor(props: any) {
    super(props);
    this.changeMemberNumber = this.changeMemberNumber.bind(this);
    this.changeTime = this.changeTime.bind(this);
    this.state = {
      visibility: false,
    };
  }
  renderPickerViewData(start: number, end: number, step: number) {
    let allDate: Array<any> = [];
    let timeStep: any = [{label: "不限时长", value: 0} ];
    for (start; start <= end; ) {
      let leftTime = start % 1;
      let reallyTime = Math.floor(start);
      let item = {
        label: `${reallyTime <= 9 ? `0${reallyTime}` : reallyTime}:${
          leftTime * 60 === 0 ? "00" : leftTime * 60
        }`,
        value: `${reallyTime <= 9 ? `0${reallyTime}` : reallyTime}:${
          leftTime * 60 === 0 ? "00" : leftTime * 60
        }`,
      };
      timeStep.push(item);
      start = start + step;
    }
    let durTime = [
      {label: "不限时长", value: 0},
      {label: "0.5小时", value: 30},
      {label: "1小时", value: 60},
      {label: "1.5小时", value: 90},
      {label: "2小时", value: 120},
      {label: "2.5小时", value: 150},
      {label: "3小时", value: 180},
      {label: "4小时", value: 240},
      {label: "5小时", value: 300},
      {label: "6小时", value: 360},
      {label: "7小时", value: 420},
      {label: "8小时", value: 480},
      {label: "9小时", value: 540},
      {label: "10小时", value: 100},
    ];
    allDate = [timeStep, durTime];
    return allDate;
  }
  changeMemberNumber(data: Array<string | number>) {
    const [minCapacity, maxCapacity] = data;
    this.props.store.setSearchRoomParmasFreeFilter({
      minCapacity,
      maxCapacity,
      includeMeetings: "false",
    });
  }
  changeTime(data: Array<string | number>) {
    let [start, during] = data;
    if (start) {
      this.props.store.setSearchRoomParmasFreeFilter({
        start,
        during,
        includeMeetings: "false",
      });
    }
  }
  renderPeopleNumber(data: string) {
    return memberNumberTag.find((a) => {
      return a.val === data;
    });
  }
  render() {
    const {visibility} = this.state;
    let allData = this.renderPickerViewData(8, 23, 0.5);
    let allSetting = this.props.store.allSetting;
    let allRoomsCount = this.props.store.allRoomsCount;
    let searchRoomParmas: SearchRoomParmas = this.props.store.searchRoomParmas;
    allSetting = toJS(allSetting);
    let {allFacilities = []} = allSetting || {};
    return (
      <>
        <div
          className="filter-content"
          onClick={(e) => {
            e.stopPropagation();
            this.setState({visibility: !visibility});
          }}
        >
          <div className="filter-content-condition">
            {searchRoomParmas.start && searchRoomParmas.during
              ? `${searchRoomParmas.start}-
                         ${moment(
                           `${searchRoomParmas.filterDate} ${searchRoomParmas.start}`
                         )
                           .clone()
                           .add(searchRoomParmas.during, "minutes")
                           .format("HH:mm")}
                        `
              : "时间"}{" "}
            &nbsp;|&nbsp;
            {searchRoomParmas.maxCapacity && searchRoomParmas.minCapacity
              ? this.renderPeopleNumber(
                  `${searchRoomParmas.minCapacity}-${searchRoomParmas.maxCapacity}`
                )?.label
              : "人数"}{" "}
            &nbsp;|&nbsp;
            {toJS(searchRoomParmas.facilities) &&
            !!toJS(searchRoomParmas.facilities)?.length
              ? [...allFacilities]
                  .map(({key, value}: {key: string; value: string}) => {
                    if (searchRoomParmas.facilities?.includes(key)) {
                      return value;
                    }
                  })
                  .filter((a) => a)
                  .join("、")
              : "设备"}
            <span className="iconfont icon-xia" />
          </div>
          <div className="filter-content-icon">
            <span className="iconfont icon-close" />
          </div>
        </div>
        <MobileModel
          title={moment(searchRoomParmas.filterDate).format("YYYY-MM-DD")}
          onResetText={"清除全部"}
          visibility={visibility}
          onClose={() => {
            this.setState({visibility: !visibility});
            this.props.store.setSearchRoomParmasFreeFilter({
              includeMeetings: "true",
            });
          }}
          onSuer={() => {
            this.setState({visibility: !visibility});
            this.props.store.setSearchRoomParmasFreeFilter({
              includeMeetings: "true",
            });
          }}
          btnText={
             `${allRoomsCount}会议室可使用` 
          }
          onReset={() => {
            this.props.store.resetSearchRoomParmas();
          }}
        >
          <div className="filter-item">
            <div className="filter-title">时间</div>
            <div className="filter-time-tips">
              <div> 开始时间 </div>
              <div> 时长 </div>
            </div>
            <div className="filter-item-content">
              {!isMobile && (
                <PcTimeFilter
                  itemData={[...allData]}
                  onChange={this.changeTime}
                  value={[
                    searchRoomParmas.start ? searchRoomParmas.start : 0,
                    searchRoomParmas.during ? searchRoomParmas.during : 0,
                  ]}
                />
              )}

              {isMobile && (
                <PickerView
                  value={[
                    searchRoomParmas.start ? searchRoomParmas.start : 0,
                    searchRoomParmas.during ? searchRoomParmas.during : 0,
                  ]}
                  onChange={this.changeTime}
                  data={allData}
                  cascade={false}
                />
              )}
            </div>
          </div>
          <div className="filter-item">
            <div className="filter-title">人数</div>
            <div className="filter-item-content">
              {memberNumberTag.map((item) => {
                return (
                  <div
                    className={classnames("filter-item-content-tips", {
                      "active-tips":
                        [
                          searchRoomParmas.minCapacity,
                          searchRoomParmas.maxCapacity,
                        ].join("-") === item.val,
                    })}
                    key={item.val}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (
                        [
                          searchRoomParmas.minCapacity,
                          searchRoomParmas.maxCapacity,
                        ].join("-") === item.val
                      ) {
                        this.changeMemberNumber([]);
                        return;
                      }
                      this.changeMemberNumber(item.val.split("-"));
                    }}
                  >
                    {item.label}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="filter-item">
            <div className="filter-title">设备</div>
            <div className="filter-item-content">
              {allFacilities.map(
                ({key, value}: {key: string; value: string}) => {
                  return (
                    <div
                      className={classnames("filter-item-content-tips", {
                        "active-tips": searchRoomParmas.facilities?.includes(
                          key
                        ),
                      })}
                      key={key}
                      onClick={(e) => {
                        e.stopPropagation();
                        let facilities = searchRoomParmas.facilities || [];
                        if (facilities && facilities.includes(key)) {
                          facilities = (facilities || []).filter(
                            (a: string) => a !== key
                          );
                        } else {
                          facilities.push(key);
                        }
                        this.props.store.setSearchRoomParmasFreeFilter({
                          facilities,
                          includeMeetings: "false",
                        });
                      }}
                    >
                      {value}
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </MobileModel>
      </>
    );
  }
}

export default (props: any) => {
  return (
    <Provider store={appState}>
      <HoomFilter {...props} />
    </Provider>
  );
};
