import React from "react";
import {Select} from "antd";
import './public.scss';
import { time } from "console";
const {Option} = Select;
interface PcTimeFilterPorps {
  itemData: Array<any>;
  value:Array<any>;
  onChange: (val: any) => void;
}

interface PcTimeFilterStatus{
    time:Array<string|undefined>;
}

class PcTimeFilter extends React.Component<PcTimeFilterPorps,PcTimeFilterStatus> {
  constructor(props:any){
      super(props)
    this.state = {
        time:[]
    }
  }
  componentDidMount(){
    const {value} = this.props;
    this.setState({
        time:[...value]
    })
  }

  render() {
    const {onChange, itemData,value} = this.props;
    const [filterStartTime, filtersDur] = itemData;
    const [start,dur] = this.state.time;
    console.log(start,start)
    return (
      <div className="pc-filter-time-conetent">
        <div className="pc-filter-time-conetent-left">
          <Select
            value={start}
            onChange={(val:string)=>{
                let data = [val,dur];
                this.setState({
                    time:[...data]
                })
                onChange(data)
            }}
          >
                {
                   filterStartTime.map((item:any)=>{
                       return <Option key={item.value} value={item.value} >{item.label}</Option>
                   }) 
                }
        </Select>
        </div>
        <div className="pc-filter-time-conetent-left">
        <Select
            value={dur}
            onChange={(val:string)=>{
                let data = [start,val ];
                onChange(data)
                this.setState({
                    time:[...data]
                })
            }}
        >
                {
                   filtersDur.map((item:any)=>{
                       return <Option key={item.value} value={item.value} >{item.label}</Option>
                   }) 
                }
        </Select>
        </div>
      </div>
    );
  }
}
export default PcTimeFilter;
