import React from "react";
import "./styles/index.scss";
import ScanningHomePage from "./pages/home";
import HomeDetial from './pages/BookingDetial';
import BookingDetial from './pages/BookingSuccess';
import MeettingDetial from './pages/MeettingDetial';
import ScanningAreaChoicePage from "./pages/areaChoice";
import ScanningOtherAreaPage from "./pages/areaChoice/otherArea";
import ScanningMyReservePage from "./pages/myReserve";
import routerHelper from './utils/helper/router.helper';
import SelectUser from './pages/Components/SelectPersion';
import MeetingModify from './pages/MeetingModify';


import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

function MobileRoute() {
  return (
    <div className="App-Content">
      <Router>
        <Switch>
          <Route
            exact
            path={routerHelper.getMobileUrl(`homePage`)}
            name="ScanningHomePage"
            component={ScanningHomePage}
          />
          {/* 预定 */}
          <Route
            exact
            path={routerHelper.getMobileUrl(`homeDetial/:roomId`)}
            name="HomeDetial"
            component={HomeDetial}
          />
          {/* 详情 */}
          <Route
            exact
            path={routerHelper.getMobileUrl(`bookingDetial/:meetingId`)}
            name="BookingDetial"
            component={BookingDetial}
          />
          {/* 修改 */}
          <Route
            exact
            path={routerHelper.getMobileUrl(`meetingModify/:meetingId`)}
            name="meetingModify"
            component={MeetingModify}
          />
          {/* 会议详情 */}
          <Route
            exact
            path={routerHelper.getMobileUrl(`meettingDetial/:meetingId`)}
            name="MeettingDetial"
            component={MeettingDetial}
          />
          <Route
            exact
            path={routerHelper.getMobileUrl(`areaChoice`)}
            name="ScanningAreaChoicePage"
            component={ScanningAreaChoicePage}
          />
          <Route
              exact
              path={routerHelper.getMobileUrl(`otherArea`)}
              name="ScanningOtherAreaPage"
              component={ScanningOtherAreaPage}
          />
          <Route
              exact
              path={routerHelper.getMobileUrl(`my-meetings`)}
              name="ScanningMyReservePage"
              component={ScanningMyReservePage}
          />
          <SelectUser/>
          </Switch>
      </Router>
    </div>
  )
}

export default MobileRoute;
