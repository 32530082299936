import React from "react";
import appState from "../appStatus";
import { observer, inject, Provider } from "mobx-react";
import './styles/myReserve.scss';
import moment from 'moment';
import routerHelper from '../../utils/helper/router.helper';
import { Toast } from 'antd-mobile';
interface ScanningMyReserveStatus {
    meetings: any[],
    loading: boolean
}

@inject('store')
@observer
class MyReserve extends React.Component<any, ScanningMyReserveStatus> {
    constructor(props: any) {
        super(props);
        this.state = {
            meetings: [], // 所有预订会议室
            loading: false
        }
    }
    dateMeetings(meetings: any[]) {
        const dateMeetings: any[] = []
        let dates: string[] = meetings.map(x => x.beginTime.slice(0, 10));
        for (let x of Array.from(new Set(dates))) {
            const todayMeetings: any[] = []
            meetings.forEach(y => {
                if (y.beginTime.slice(0, 10) === x) {
                    todayMeetings.push(y)
                }
            })
            dateMeetings.push({
                day: x,
                todayMeetings,
            })
        }
        return dateMeetings
    }

    componentDidMount(): void {
        const beginAt = new Date();
        const endAt = new Date(beginAt);
        endAt.setDate(beginAt.getDate() + 30);
        const params: any = {
            pageSize: 400,
            beginAt: moment(beginAt).format('YYYY-MM-DD'),
            endAt: moment(endAt).format('YYYY-MM-DD'),
            type: 'all'
        }
        Toast.loading('Loading...', 10000);
        appState.getHistoryMeetings(params).then(res => {
            this.setState({ meetings: this.dateMeetings(res) })
            Toast.hide()
        })
    }

    formatDate(date: string): string {
        let today = date.split('-')[1] + " 月 " + date.split('-')[2]
        if (moment(new Date()).format('YYYY-MM-DD') === date) {
            today += "今天"
        } else {
            const dddd = moment(date, 'YYYY-MM-DD').format('dddd')
            today += " " + dddd
        }
        return today
    }
    formatTime(time: string): string {
        return time.split(' ')[1].slice(0, 5)
    }
    getDuration(beginTime: string, endTime: string) {
        const ends: string[] = endTime.split(' ')[1].split(':')
        const begins: string[] = beginTime.split(' ')[1].split(':')
        const hour = Number(ends[0]) - Number(begins[0])
        const min = Number(ends[1]) - Number(begins[1])
        return (hour + min / 60).toFixed(2);
    }
    render() {
        if (this.state.meetings.length === 0) {
            return (
                <div className="my-reserve-content">
                    <p>暂无会议安排</p>
                </div>
            );
        }
        return (
            <div className="my-reserve-content">
                {
                    this.state.meetings.map((item: any, index: number) =>
                        <div className="oneDay" key={index}>
                            <div className={index === 0 ? "date" : "date1"}>{this.formatDate(item.day)}</div>
                            {
                                item.todayMeetings.length ?
                                    item.todayMeetings.map((item1: any, index1: number) =>
                                        <div
                                            className="meeting"
                                            key={index1}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                const { history } = this.props;
                                                let path = routerHelper.getMobileUrl(`meettingDetial/${item1.id}`);
                                                history.push(path)
                                            }}
                                        >
                                            <div className="meeting-icon">
                                                <div className="icon">
                                                    <span className="iconfont icon-baibanwhiteboard10" />
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="top">
                                                    <div className="before">{this.formatTime(item1.beginTime)}</div>
                                                    <div className="after">{item1.subject}</div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="before">{this.formatTime(item1.endTime)}</div>
                                                    <div className="after">{this.getDuration(item1.beginTime, item1.endTime)}小时 | {item1.roomName}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    : <div className="meeting none">暂无预订记录</div>
                            }
                        </div>
                    )
                }
            </div>
        );
    }
}

export default (props: any) => {
    return (
        <Provider store={appState}>
            <MyReserve {...props} />
        </Provider>
    );
};

