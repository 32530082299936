import React from "react";
import classnames from 'classnames';
interface RadioItemProps {
    showName: string;
    code: string;
    hint: string;
    valueScope: Array<string>;
    onChange: (val: any) => void;
    value?: Array<string>;
}

class CheckBoxItem extends React.Component<RadioItemProps> {
    render() {
        const { showName, hint, code, valueScope, value = [], onChange } = this.props;
        return (
            <div className="detial-page-place">
                <h3>{showName} {hint &&
                    <small>({hint})</small>
                }
                </h3>
                <div className="detial-page-server">
                    {valueScope.map(a => {
                        return (
                            <p key={`${code}_${a}`}
                                className={classnames({
                                    active: value && value.includes(a)
                                })}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    let data: any = {};
                                    if (value && value.includes(a)) {
                                        data[code] = {
                                            value: [...value].filter(b => b !== a)
                                        }
                                    } else {
                                        data[code] = {
                                            value: [...value, a]
                                        }
                                    }
                                    onChange({ ...data })
                                }}
                            >
                                {a}
                            </p>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default CheckBoxItem;
