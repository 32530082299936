import React from "react";
import appState from "../appStatus";
import UserStatus from '../Components/SelectPersion/status';
import { observer, inject, Provider } from "mobx-react";
import './styles/index.scss';
import { Calendar, TextareaItem, Toast, Modal } from 'antd-mobile';
import moment from 'moment';
import { toJS } from 'mobx';
import zhCN from 'antd-mobile/lib/calendar/locale/zh_CN';
import classnames from 'classnames'
import routerHelper from '../../utils/helper/router.helper';
import TimeRangeSelector from 'react-time-range-selector';
import utils, { disableTimes } from '../../utils';
import ServerItem from '../Components/ServerItem';
import BootFooter from '../Components/BottomFooter';

// import { Link } from "react-router-dom";
// import { Icon } from 'antd-mobile';
// import Translate from '../appStatus/language/language';
// zh-cn

moment.locale('zh-cn');

interface HomeDetialStatus {
  showCalendar: boolean;
  meetingDetail: any,
  meetingVisible: boolean,
  isOnChange:boolean;
  //   nowDate: any;
  //   roomId?: string;
}

@inject('store', 'userStatus')
@observer
class MeetingModify extends React.Component<any, HomeDetialStatus> {
  timeSet:any = '' ;
  constructor(props: any) {
    super(props);
    this.handleSumit = this.handleSumit.bind(this);
    this.hasMeetting = this.hasMeetting.bind(this);
    this.hasRestInfo = this.hasRestInfo.bind(this);
    this.getAllServers = this.getAllServers.bind(this);
    this.state = {
      showCalendar: false,
      meetingDetail: {} as any,
      meetingVisible: false,
      isOnChange:false

    }
  }
  componentDidMount() {
    const { match } = this.props;
    const { store } = this.props;
    let { bookingInfo } = store;
    const { id } = bookingInfo || {};
    if (id !== match.params.meetingId) {
      this.hasRestInfo();
    }
  }

  renderDate(date: moment.Moment) {
    if (moment().isSame(date, 'day')) {
      return '今天';
    }
    if (moment().add(1, 'day').isSame(date, 'day')) {
      return '明天';
    }
    return moment(date).clone().format('ddd')
  }

  hasRestInfo() {
    const { match } = this.props;
    appState.getMeeting(match.params.meetingId).then(res => {
      const { roomId, beginTime, extras, subject, ownerName, duration, ownerId, ownerDeptName } = res || {};
      let user = {
        id: ownerId,
        deptName: ownerDeptName,
        name: ownerName
      };
      let meetingDate = moment(beginTime).format('YYYY-MM-DD') || moment().format('YYYY-MM-DD');
      let data = {
        subject,
        id: match.params.meetingId,
        roomId: roomId,
        duration: duration || 30,
        ownerInfo: { ...user },
        ownerId: ownerId,
        extras: { ...extras },
        startTime: moment(beginTime).format('HH:mm'),
        meetingDate: meetingDate,
      }
      this.props.store.setBookingInfo({ ...data });
      appState.getRoomInfoDay(roomId, meetingDate);
      appState.getAllSetting('ROOM', roomId, true);
    })
  }

  handleSumit() {
    const { store } = this.props;
    let { bookingInfo } = store;
    const { subject } = bookingInfo || {};
    if (!subject) {
      Toast.fail('会议主题不存在！', 1)
      return;
    }
    this.props.store.modifyMeeting().then((res: any) => {
      const { warnMessage } = res || {};
      if (warnMessage) {
        return;
      }
      const { match, history } = this.props;
      let path = routerHelper.getMobileUrl(`bookingDetial/${match.params.meetingId}`)
      history.push(path);
    });
  }

  hasMeetting(meetings: any[]) {
    let hasMeeting = meetings.find((item) => item.abilities.includes("ongoing"));
    if (hasMeeting) {
      return hasMeeting;
    } else {
      return undefined;
    }
  }

  showMeeting(meeting: any) {
    this.setState({
      meetingDetail: meeting,
      meetingVisible: true
    })
  }

  handleMeetingClose() {
    this.setState({ meetingVisible: false })
  }

  renderDisableTime(meetings: Array<any>) {
    let disableTime = [...disableTimes];
    const { match } = this.props;
    // match.params.meetingId
    meetings.map((data: any) => {
      let { beginTime, duration, id } = data || {};
      if (id !== match.params.meetingId && beginTime && duration) {
        let time: any[] = utils.timeChangeNumber(moment(beginTime), duration)
        disableTime.push(time.concat(['rgba(253, 148, 10, 1)']))
      }
    })
    return disableTime;
  }

  handleContainerClick = (val: number) => {
      // 防止onChange事件事 也执行
      if(this.state.isOnChange){
        return;
      }
    const { store } = this.props;
    let { roomInfo = {}, bookingInfo } = store;
    let time = utils.timeChangeNumber(moment(`${bookingInfo.meetingDate} ${bookingInfo.startTime}`), bookingInfo.duration)
    const [start, end] = time;
    const { meetings = [] } = roomInfo || {};

    if (start < val && val < end) {
      return
    }

    for (let i = 0; i < meetings.length; i++) {
      const meeting = meetings[i]
      let { beginTime, duration } = meeting || {};
      if (beginTime && duration) {
        let time = utils.timeChangeNumber(moment(beginTime), duration)
        if (time[0] <= val && val <= time[1]) {
          this.showMeeting(meeting)
          return;
        }
      }
    }

    if (disableTimes.some(x => x[0] < val && val < x[1])) {
      return;
    }
    const hourse: number = start - val
    const val2 = [val, end - hourse]
    let timeDate = utils.numberChangeToTime(val2)
    let data = {
      startTime: timeDate.time,
      duration: timeDate.dur
    }
    this.props.store.setBookingInfo({ ...data });
  }

  getAllServers() {
    const { store } = this.props;
    let { allSetting } = store;
    let meetingServer: Array<any> = [];
    let bookingServices = allSetting && allSetting.roomTypeCustomForms.filter((form: any) => form.resourceType === 'ROOM' && form.fields);
    if (bookingServices && bookingServices.length > 0) {
      for (let i = 0; i < bookingServices.length; i++) {
        if (bookingServices[i].fields) {
          bookingServices[i].fields.forEach((value: any) => {
            meetingServer.push(toJS(value));
          });
        }
      }
    }
    return meetingServer;
  }
  render() {
    const { store, userStatus,match } = this.props;
    let { roomInfo = {}, bookingInfo, allSetting, filterFacilities } = store;
    const { meetingTimes } = allSetting || {};
    const { bookingLimitDays } = meetingTimes || {}
    const { ownerInfo, subject, extras } = bookingInfo || {};
    let time = utils.timeChangeNumber(moment(`${bookingInfo.meetingDate} ${bookingInfo.startTime}`), bookingInfo.duration)
    const [start, end] = time;
    roomInfo = toJS(roomInfo)
    filterFacilities = toJS(filterFacilities)
    const { meetings, officeAreaAddress, defaultImageUrl, facilities = [] } = roomInfo || {};
    const disableTime = utils.renderDisableTime(meetings,match.params.meetingId);
    const isMobile = (window as any).isMobile
    let meetingServer = this.getAllServers();
    return (
      <div className="detial-page-content">
        <div className="detial-page-img">
          {defaultImageUrl ?
            <img src={defaultImageUrl} alt="图片" /> :
            <img src={require('./images/demo.jpg')} alt="暂无图片" />
          }

        </div>

        <div className="detial-page-info">
          <h3>{roomInfo.name}</h3>
          <p> {roomInfo.capacity ? roomInfo.capacity + '个座位' : ""} {filterFacilities.map((a: any) => {
            if (facilities.includes(a.key)) {
              return `| ${a.value}`;
            }
          })} </p>
        </div>
        {
          !isMobile && <div className="meeting-detial-des tips">
            <span>使用 shift + 滚轮 滑动时间轴</span>
          </div>
        }
        <TimeRangeSelector
          height={50}
          isMobile={isMobile}
          initialScrollLeft={typeof start === "number" ? start * 100 : (new Date()).getHours() * 100}
          value={[start, end]}
          disableBoxBorderWidth={2}
          range={'day'}
          splitWidth={100}
          snap={0.25}
          onContainClick={this.handleContainerClick}
          disabledTimeRanges={[...disableTime] as any}
          onChange={(val: any) => {
            if(this.timeSet){
              clearTimeout(this.timeSet);
            }
            let timeDate = utils.numberChangeToTime(val);
            this.setState({isOnChange:true })
            let data = {
              startTime: timeDate.time,
              duration: timeDate.dur,
            };
            this.props.store.setBookingInfo({ ...data });
            this.timeSet = setTimeout(()=>{
              this.setState({isOnChange:false })
            }, 200)
          }}
          onDisableclick={(val: any)=>{
            if(this.timeSet){
              clearTimeout(this.timeSet);
            }
            this.setState({isOnChange:true })
             this.showMeeting(val);
             this.timeSet = setTimeout(()=>{
              this.setState({isOnChange:false })
            }, 200)
          }}
        />
        <div className="detial-page-time">
          <div
            className={classnames('detial-page-time-reduce', {
              active: bookingInfo.duration - 15 > 0
            })}
            onClick={(e) => {
              e.stopPropagation();
              if (bookingInfo.duration <= 15) {
                return
              }
              let data = {
                duration: bookingInfo.duration - 15
              }
              this.props.store.setBookingInfo({ ...data });
            }}
          >
            <span className="iconfont icon-iconset0187 iconAdd" />
          </div>
          <div className="detial-page-time-center">
            {bookingInfo.startTime} - {moment(`${bookingInfo.meetingDate} ${bookingInfo.startTime}`).add(bookingInfo.duration, 'minutes').format('HH:mm')},
            {bookingInfo.duration} 分钟
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              let data = {
                duration: bookingInfo.duration + 15
              }
              this.props.store.setBookingInfo({ ...data });
            }}
            className="detial-page-time-add active"
          >
            <span className="iconfont icon-add1 iconAdd" />
          </div>
        </div>

        <div className="detial-page-date">
          <h3>日期</h3>
          <div
            onClick={(e) => {
              e.stopPropagation();
              this.setState({ showCalendar: !this.state.showCalendar })
            }}
          >
            {moment(bookingInfo.meetingDate).format('YYYY-MM-DD')}&nbsp;{this.renderDate(bookingInfo.meetingDate)}
            <span className="iconfont icon-right iconrignt" />
          </div>
        </div>
        <div className="detial-page-place">
          <h3>会议主题</h3>
          <div className="detial-page-place-p">
            <TextareaItem
              autoHeight
              rows={2}
              value={subject}
              placeholder="请输入主题"
              color={'#999'}
              onChange={(val: any) => {
                store.setBookingInfo({
                  subject: val
                });
              }}
              maxLength={200}
            />
          </div>
        </div>
        <div
          className="detial-page-place"
          onClick={(e) => {
            e.stopPropagation();
            userStatus.initUserSeletStatus('Single', [ownerInfo])
            let path = routerHelper.getMobileUrl('selectuser');
            const { history } = this.props;
            history.push(path);
          }}
        >
          <h3>会议主持人</h3>
          <p className="detial-page-place-p">
            {ownerInfo ? `${ownerInfo.name}-${ownerInfo.deptName}` : '请选择人员'}
            <span className="iconfont icon-right float-icon-right" />
          </p>
        </div>
        {officeAreaAddress &&
          <div className="detial-page-place">
            <h3>会议室地址</h3>
            <p className="detial-page-place-p">{officeAreaAddress}</p>
          </div>
        }

        {meetingServer.map((a: any, index: number) => {
          let newItem = {
            ...a,
            value: extras[a.code]?.value,
            onChange: (val: any) => {
              let data = {
                extras: { ...extras, ...val }
              }
              store.setBookingInfo({ ...data })
            }
          }
          return <ServerItem {...newItem} key={`${a.code}_${index}`} itemKey={`${a.code}_${index}2`} />
        })}

        <div className="detial-page-place">
          <h3>取消预订规则</h3>
          <p>会议开始前可随时取消预订，或联系管理员取消；会议开始后，只能提前结束会议。</p>
        </div>

        <BootFooter />

        <div className="detial-page-footer">
          <div
            className="detial-page-footer-btn"
            onClick={(e) => {
              e.stopPropagation();
              this.handleSumit();
            }}
          >
            修改
          </div>
        </div>
        <Calendar
          type={'one'}
          locale={zhCN}
          defaultValue={[moment(bookingInfo.meetingDate).toDate(), moment(bookingInfo.meetingDate).toDate()]}
          visible={this.state.showCalendar}
          onCancel={() => {
            this.setState({ showCalendar: !this.state.showCalendar })
          }}
          onConfirm={(data: any) => {
            console.log('selectDay = ', data)
            let selectDay = moment(data).format('YYYY-MM-DD');
            this.props.store.setBookingInfo({ meetingDate: selectDay });
            this.setState({ showCalendar: !this.state.showCalendar })

            appState.getRoomInfoDay(roomInfo.id, selectDay);
          }}
          minDate={moment().toDate()}
          maxDate={bookingLimitDays ? moment().add(bookingLimitDays, 'days').toDate() : undefined}
        />
        <Modal
          visible={this.state.meetingVisible}
          transparent
          maskClosable={false}
          footer={[{ text: '关闭', onPress: () => this.handleMeetingClose() }]}
        >
          <div style={{ display: 'flex', marginBottom: 12 }}>
            预订人：{this.state.meetingDetail?.ownerName}
          </div>
          <div style={{ display: 'flex', marginBottom: 12 }}>
            部门：{this.state.meetingDetail?.ownerDeptName}
          </div>
          <div style={{ display: 'flex' }}>
            手机：{this.state.meetingDetail?.ownerMobile ?
              <a href={`tel:${this.state.meetingDetail?.ownerMobile}`}>{this.state.meetingDetail?.ownerMobile}</a> : '暂无'
            }
          </div>
          <div style={{ display: 'flex' }}>请于企业微信查看预定人联系号码</div>
        </Modal>
      </div>
    );
  }
}

export default (props: any) => {
  return (
    <Provider store={appState} userStatus={UserStatus}>
      <MeetingModify {...props} />
    </Provider>
  );
};

