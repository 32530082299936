import { requestHelper } from "../../../../utils/helper/request.helper";
import moment from 'moment';


export class UserServer {
    getSearchUser(key?:string){
        let path = `search-users?keyword=${key}`; 
        return  requestHelper.get(path).then(res=>{
            return res.data.data || [];
        })
    }
}
const userServer = new UserServer();
export default userServer;