import './styles/index.scss';

import utils, { disableTimes } from '../../../../utils/index';

import { Link } from "react-router-dom";
import { Modal } from "antd-mobile";
import React from 'react';
import TimeRangeSelector from 'react-time-range-selector';
import classnames from 'classnames';
import moment from "moment";
import routerHelper from '../../../../utils/helper/router.helper';

interface RoomListItemProps {
  id: string;
  name: string;
  officeArea: string;
  capacity: number;
  meetings?: Array<any>;
  scrollLeft: number;
  onScrollLeftChange: (val: number) => void,
  onChange: () => void;
  [key: string]: any;
}

class RoomListItem extends React.Component<RoomListItemProps> {

  state = {
    meetingDetail: {} as any,
    meetingVisible: false
  }

  showMeeting(meeting: any) {
    this.setState({
      meetingDetail: meeting,
      meetingVisible: true
    })
  }




  handleMeetingClose() {
    this.setState({ meetingVisible: false })
  }

  getStartTimeNumber(scrollLeft: number, time?: string): number {
    if (time) {
      let [h, m] = time.split(':');
      m = utils.round(Number(m) / 60) as any
      h = m + Number(h);
      return Number(h);
    }
    return scrollLeft;
  }

  onContainClick = (val: number) => {
    const { onChange } = this.props;
    onChange()
  }
  renderRoomName(name:string){
    let newName:any = name;
    if(newName.includes('/')){
      newName =  newName.split('/')
      console.log(newName);
      newName = <>
      {newName.map((a:string,index:number) =>{
        if(index+1 === newName.lenght){
          return a
        } else {
          return <>{a}<br/></>
        }
      } )}
      </>
        console.log(name)
    }
    return newName;
  }
  render() {
    const {name, officeArea, disabled, onChange,notice, history, disabledReason, start, capacity, defaultImageUrl, scrollLeft, meetings = [], abilities = [] } = this.props;
    let disableTime = utils.renderDisableTime(meetings);
    let startTime = this.getStartTimeNumber(scrollLeft, start)
    // console.log('startTime:', startTime, ', scrollLeft:', scrollLeft, ', start:', start);

    let disableBooking = disabled || (abilities && !abilities.includes('booking'));

    return (
      <div className={
        classnames('home-item-content', {
          'home-item-content-disable': disableBooking
        })
      }>
        <div
          onClick={(e) => {
            e.stopPropagation()
            if (!disableBooking) {
              onChange();
            }
          }}
        >
          <div className="home-item-info">
            <div className="left-info">
              <h3>
                {this.renderRoomName(name)}
              </h3>
              {!!capacity &&
                <div className="item-info">
                  <div className="icon-label">
                    <span className="iconfont icon-tuanduichengyuan" />
                  </div>
                  <div className="info-text">
                    {capacity} 个座位
                </div>
                </div>
              }
              <div className="item-info">
                <div className="icon-label">
                  <span className="iconfont icon-didian" />
                </div>
                <div className="info-text">
                  {officeArea}
                </div>
              </div>
              {disableBooking && ( disabledReason || notice) &&
                <div className="item-info">
                  <div className="info-text error-text">
                    {disabledReason || notice}
                  </div>
                </div>
              }
            </div>
            <div className="right-img">
              {defaultImageUrl ? <img src={defaultImageUrl} alt="图片" /> :
                <img src={require('./images/demo.png')} alt="暂无图片" />}
            </div>
          </div>
        {
          !isMobile && <div className="meeting-detial-des tips" style={{
            float: 'right'
          }}>
            <span>使用 shift + 滚轮 滑动时间轴</span>
          </div>
        }
        </div>
        <TimeRangeSelector
          disabled={disableBooking}
          height={50}
          isMobile={(window as any).isMobile}
          disableBoxBorderWidth={2}
          scrollLeft={Number(startTime) * 50}
          onContainClick={this.onContainClick}
          range={'day'}
          splitWidth={50}
          snap={0.25}
          disabledTimeRanges={[...disableTime] as any}
        />
      </div>
    )
  }
}

export default RoomListItem;
