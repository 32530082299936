import { makeAutoObservable,toJS } from "mobx";

import "dayjs/locale/zh-cn";
import moment from 'moment';
import userServer from './server';

class ServerStatus {
  selectUser:Array<any> = [];
  // Multiple;
  selectModel:string = 'Single';
  modify:boolean = false;
  constructor() {
    makeAutoObservable(this);
  }
  initUserSeletStatus(status:string,selectUser:any[]){
    this.selectModel = status;
    this.selectUser = selectUser;
  }
  setSelectUser(user:any){
    if(this.selectModel){
      this.selectUser = [user];
    } else {
      this.selectUser = [...this.selectUser,user]
    }
  }
  setModify(modify:boolean){
    this.modify = modify;
  }
  getUserList(key?:string){
      return userServer.getSearchUser(key)
  }
}

const serverStatus = new ServerStatus();
export default serverStatus;
