import moment from "moment";
import { requestHelper } from "../../utils/helper/request.helper";

interface MeetingStatus {
  subject:string;
  roomId: string;
  time: any;
  during: number;
}

export interface SearchRoomParmas {
  start?:string;
  end?:string;
  facilities?:Array<any>;
  officeAreaIds?: Array<any>;
  minCapacity?:number;
  maxCapacity?:number;
  busyType?:string
  during?:number;
  includeMeetings?:string;
  filterDate:string;
}
export class AppServer {
  getRoomInfo(roomId: string) {
    return requestHelper
      .get(`meeting-rooms/${roomId}?result=full`)
      .then((res) => {
        let room = res.data.data;
        if (room.deviceStatus) {
          for (let key in room.deviceStatus) {
            if (room.deviceStatus[key] !== undefined) {
              room[key] = room.deviceStatus[key];
            }
          }
        }
        room.meetings = room.meetings ? room.meetings : [];
        return room;
      });
  }
  getRoomInfoDay(roomId: string, meetingDate: string) {
    return requestHelper
      .get(`meeting-rooms/${roomId}?meetingDate=${meetingDate}`)
      .then((res) => {
        let room = res.data.data;
        if (room.deviceStatus) {
          for (let key in room.deviceStatus) {
            if (room.deviceStatus[key] !== undefined) {
              room[key] = room.deviceStatus[key];
            }
          }
        }
        room.meetings = room.meetings ? room.meetings : [];
        return room;
      });
  }
  getAreas() {
    return requestHelper
      .get("office-areas", {
        params: {
          roomType: "ROOM",
        },
      })
      .then((res) => {
        return res.data.data;
      });
  }

  getOneAreas(areaId:string) {
    return requestHelper
      .get(`office-areas?${areaId}`, {
        params: {
          roomType: "ROOM",
        },
      })
      .then((res) => {
        return res.data.data;
      });
  }

  getHistoryMeetings(params: any) {
    return requestHelper
      .get("history-meetings", {
        params,
      })
      .then((res) => {
        return res.data.data;
      });
  }
  getAllMeetingRoomsWidhtStatus(params: SearchRoomParmas) {
    let busyType = params.busyType || 'all';
    let officeAreaUrl: string = "";
    params.officeAreaIds &&
      params.officeAreaIds.map((officeArea) => {
        officeAreaUrl += `&officeAreaIds=${officeArea}`;
      });
    if(params.start){
      officeAreaUrl += `&start=${params.start}`;
    }
    if(params.end){
      officeAreaUrl += `&end=${params.end}`;
    }
    if(params.maxCapacity){
      officeAreaUrl += `&maxCapacity=${params.maxCapacity}`;
    }
    if(params.minCapacity){
      officeAreaUrl += `&minCapacity=${params.minCapacity}`;
    }
    if(params.facilities){
      officeAreaUrl += `&facilities=${params.facilities.join(',')}`;
    }
    if(params.includeMeetings){
      officeAreaUrl += `&includeMeetings=${params.includeMeetings}`;
    }
    officeAreaUrl += `&busyType=${busyType}`;
    console.log('getAllMeetingRoomsWidhtStatus params=', params)
    return requestHelper
      .get(`choose-video-conferencing-rooms?${officeAreaUrl}`)
      .then<any>((res) => {
        return res.data.data;
      });
  }
  finishMeeting(id: string) {
    return requestHelper.put(`/meetings/${id}/finish`).then((res) => {
      return res;
    });
  }
  cancelMeeting(id: string) {
    return requestHelper.delete(`/meetings/${id}`).then((res) => {
      return res;
    });
  }
  delayMeeting(id: string, during: number) {
    return requestHelper.put(`/meetings/${id}/delay`, {
        delayMinutes: during,
      })
      .then((res) => {
        return res;
      });
  }

  getSeting(roomType:string= "ROOM",officeAreaId:string ='all',extraRoomId?:string,roomId?:string,filterDate?:string){
    let url = `settings?roomType=${roomType}`;
    if(officeAreaId){
      url += `&officeAreaId=${officeAreaId ? officeAreaId :'all' }`
    }
    if(extraRoomId){
      url += `&extraRoomId=${extraRoomId}`
    }
    if(roomId){
      url += `&roomId=${roomId}`
    }
    if(filterDate && filterDate !== moment().format('YYYY-MM-DD')){
      url += `&day=${filterDate}`
    }
    return requestHelper.get(url).then(res=>{
      return res.data || {};
    })
  }

  getMeeting(id:string){
    let path = `meetings/${id}`;
    return  requestHelper.get(path).then(res=>{
      return res.data.data;
    })
  }


  modifyMeeting(meeting: any) {
    return requestHelper.put(`meetings/${meeting.id}`, meeting).then<any>(res => {
      if (res.data.data) {
          return res.data.data;
      } else {
          return res.data;
      }
  });
}
  formBookingMeeting(meeting: any) {
    if (meeting.id) {
      return requestHelper
        .put(`meetings/${meeting.id}`, meeting)
        .then((res) => {
          if (res.data.data) {
            return res.data.data;
          } else {
            return res.data;
          }
        });
    } else {
      return requestHelper.post(`meetings`, meeting).then((res) => {
        if (res.data.data) {
          return res.data.data;
        } else {
          return res.data;
        }
      });
    }
  }
}
const appServer = new AppServer();
export default appServer;
