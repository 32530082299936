import "dayjs/locale/zh-cn";
import './styles/homePage.scss';

import { Provider, inject, observer } from "mobx-react";

import FooterButtom from '../Components/BottomFooter';
import HeaderCalendar from '../Components/HeaderCalendar';
import HomeNav from './components/HomeNav';
import HoomFilter from './components/HoomFilter';
import { Icon } from 'antd-mobile';
import React from "react";
import RoomListItem from './components/RoomListItem';
import appState from "../appStatus";
import moment from 'moment';
import routerHelper from '../../utils/helper/router.helper';
import { toJS } from 'mobx';

interface ScanningHomePageStatus {
  currentLeft: number;
}

@inject('store')
@observer
class HomePage extends React.Component<any, ScanningHomePageStatus> {
  scrollTop: number = 0;
  constructor(props: any) {
    super(props);

    this.renderArea = this.renderArea.bind(this);
    this.hasRestInfo = this.hasRestInfo.bind(this);
    this.state = {
      currentLeft: (new Date()).getHours()
    }
  }

  handleScrollLeftChange = (left: number) => {
    this.setState({ currentLeft: left })
  }

  renderArea() {
    let name = '全部';
    // let AllChoice:any = localStorage.getItem('isAllChoice');
    // let { isAllChoice } = JSON.parse(AllChoice);
    const { store } = this.props;
    let { selectAreas = [] } = store;
    if (selectAreas) {
      if (selectAreas.length >= 2) {
        name = [...selectAreas.map((a: any) => a.name)].slice(0, 2).join('、') + '...';
      } else {
        name = [...selectAreas.map((a: any) => a.name)].join('、');
      }
    }
    return name;
  }
  hasRestInfo() {
    const { searchRoomParmas } = this.props.store;
    const { match } = this.props;
    let data = {
      roomId: match.params.roomId,
      meetingDate: searchRoomParmas.filterDate
    }
    this.props.store.setBookingInfo({ ...data });
  }
  componentDidMount() {

    // officeArea LocalStore
    let storedOfficeAreaId = localStorage.getItem('officeAreaId');


    if (storedOfficeAreaId) {
      let officeAreaIds = storedOfficeAreaId.split(',');
      console.log('localstore officeAreaIds=', officeAreaIds);
      this.props.store.getAllSetting('ROOM', null, false, officeAreaIds);
      this.props.store.getAllMeetingRoomsWidhtStatus();
    }

    if (this.refs.itemListContent && this.props.store.scrollTop) {
      (this.refs.itemListContent as any).scrollTop = this.props.store.scrollTop;
    }
  }
  render() {
    const { store, history } = this.props;
    let { allRooms = [], searchRoomParmas, selectAreas = [], allSetting } = store;
    const { start, filterDate } = store.searchRoomParmas;
    allRooms = toJS(allRooms);
    const { meetingTimes } = allSetting || {};
    const { bookingLimitDays } = meetingTimes || {};
    console.log('start:', start, ', filterDate:', filterDate);

    // 是否当前
    const isToday = (filterDate === moment(new Date()).format('YYYY-MM-DD'))
    let myCurrentLeft = this.state.currentLeft;
    if (isToday) {
      myCurrentLeft = (new Date()).getHours();
    } else {
      myCurrentLeft = 8;
    }

    return (
      <div className="home-page-content">
        <div
          className="home-head"
          onClick={(e) => {
            e.stopPropagation();
            const { history } = this.props;
            let path = routerHelper.getMobileUrl(`areaChoice`);
            history.push(path);
          }}
        >
          <div className="top">
            <span>
              {selectAreas.length ? this.renderArea() : '全部'}
            </span>
            <span>
              <Icon
                type={'down'}
                size={'xxs'}
              />
            </span>
          </div>
          <div className='bottom'>
            点击切换区域
          </div>

        </div>
        <HeaderCalendar
          defaultDate={moment(searchRoomParmas.filterDate)}
          onChange={(data) => {
            store.setSearchRoomParmas({
              filterDate: data.format('YYYY-MM-DD')
            })
          }}
          maxDay={bookingLimitDays}
        />
        <HoomFilter />
        <div
          className="home-page-list-content"
          ref={'itemListContent'}
          onScroll={(e) => {
            console.log('scole----------->>>> 2 ', e.target)
            // if (allRooms.length > 0) {
                this.scrollTop = (e.target as any).scrollTop;
            // }
          }}
        >
          {
            allRooms.map((item: any) => {
              let data = { ...item, ...this.props }
              console.log('home data >> data:', data);
              return (
                <RoomListItem
                  key={item.id}
                  scrollLeft={myCurrentLeft}
                  start={start}
                  onScrollLeftChange={this.handleScrollLeftChange}
                  history={history}
                  onChange={() => {
                    let path = routerHelper.getMobileUrl(`homeDetial/${item.id}`);
                    this.hasRestInfo()
                    this.props.store.scrollTop = this.scrollTop;
                    history.push(path);
                  }}
                  {...data}
                />
              )
            })
          }
          <FooterButtom />
        </div>
        <HomeNav {...this.props} />
      </div>
    );
  }
}

export default (props: any) => {
  return (
    <Provider store={appState}>
      <HomePage {...props} />
    </Provider>
  );
};

