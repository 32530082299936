import React from 'react';
import './styles/index.scss';
import MobileRoute from './mobile.router';
import { LocaleProvider } from 'antd-mobile';
import enUS from 'antd/lib/locale-provider/en_US';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import './utils/moment.reset';
import 'react-time-range-selector/dist/index.css'

function AllRoute() {
  let localeLang = (loginUser.language === 'en' ? enUS : zhCN);
  return (
    <LocaleProvider locale={localeLang}>
      <div className="App-Content">
        <MobileRoute />
      </div>
    </LocaleProvider>
  );
}

export default AllRoute;
