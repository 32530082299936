import * as React from 'react';
import { observer } from 'mobx-react';
import './style/index.scss';

interface IProps {
    conflict:string;
}
function TipSattus(props:IProps){
    const { conflict } =  props
    return(
        <React.Fragment>
        {Number(conflict) * 1 === 1 && <span className="tip-status-busy " />}
        {Number(conflict) * 1 !== 1 && <span className ="tip-status-free "/>}
    </React.Fragment>
    )
}
export default TipSattus;