import React from 'react';
import RadioItem from './RedioItem';
import CheckBoxItem from './CheckBoxItem';
interface ServerItemProps{
    showName:string;
    code: string;
    hint: string;
    itemKey: string;
    valueType:string;
    valueScope:Array<string>;
    onChange:(val:any)=>void;
}
class ServerItem extends React.Component<ServerItemProps>{
    constructor(props:any){
        super(props);
    }
    render(){
        const { valueType } = this.props;
        return(
        <>
            {valueType === "RADIO" && <RadioItem {...this.props} key={this.props.itemKey}/>}
            {valueType === "CHECKBOX" && <CheckBoxItem {...this.props} key={this.props.itemKey} />}
        </>
        );
    }
}
export default ServerItem;
