import React from "react";
import classnames from 'classnames';
import routerHelper from '../../../../utils/helper/router.helper';

class HomeNav extends React.Component<any,any> {
  constructor(props: any) {
    super(props);
    this.state = {
        showList:false
    }
  }

  render() {
    const { showList } = this.state;
    return (
      <>
        {/* <div
          className={classnames('nav-to-mymeetng',{
            'nav-01':showList
          })}
          onClick={(e) => {
            e.stopPropagation();
            let path = `${baseUrl}/m/v5/react/approval`;
            window.open(path,'_self');
          }}
        >
          <span className="iconfont icon-shenpi" />
        </div> */}
        {/* <div
          className={classnames('nav-to-mymeetng',{
            'nav-02':showList
          })}
          onClick={(e) => {

          }}
        >
          <span className="iconfont icon-tongji" />
        </div> */}
        <div
          className={classnames('nav-to-mymeetng',{
            'nav-01':showList
          })}
          onClick={(e) => {
            e.stopPropagation();
            let path = routerHelper.getMobileUrl('my-meetings');
            this.props.history.push(path);
          }}
        >
          <span className="iconfont icon-meeting-management-my-meeting" />
        </div>
        <div
          className="nav-to-mymeetng"
          onClick={(e) => {
            e.stopPropagation();
            this.setState({
                showList:!showList
            })
          }}
        >

          {showList ? <span className="iconfont icon-_delete-big" />  : <span className="iconfont icon-squares" /> }
        </div>
      </>
    );
  }
}

export default HomeNav;
