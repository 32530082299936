import "dayjs/locale/zh-cn";

import appServer, { SearchRoomParmas } from "./server";
import { makeAutoObservable, toJS } from "mobx";

import { Toast } from 'antd-mobile';
import dayjs from "dayjs";
import moment from 'moment';

interface bookingStatus {
  id?:string;
  subject: string;
  roomId: string;
  startTime: any;
  meetingDate: string;
  duration: number;
  location: string;
  checkinNow?: boolean;
  areaIds: string[];
  currentAreaId?: string;
  ownerInfo?:any;
  ownerId?:string;
  extras?:any;
}


class AppStatus {
  showFilter: boolean = false;
  showCalendar: boolean = false;
  nowDate: any = dayjs();
  roomInfo: any;
  allAreas: any[] = [];
  allRooms: any[] = [];
  allRoomsCount:number = 0;
  selectAreas: any[] = [];
  busyStatus: string = "free";
  localRoomId: string = "";
  allSetting:any;
  scrollTop:number = 0;

  filterFacilities:Array<{key:string,value:string}> =[]
  bookingInfo: bookingStatus = {
    subject: "",
    roomId: "",
    meetingDate: moment().format("YYYY-MM-DD"),
    startTime: '',
    duration: 30,
    location: "",
    checkinNow: false,
    areaIds: [],
    ownerInfo:loginUser,
    ownerId: loginUser.id,
    extras:{}
    // currentAreaId: "GugasTfMHQ93P8aLdB4oPR",
  };

  searchRoomParmas:SearchRoomParmas = {
    filterDate:moment().format('YYYY-MM-DD')
  }

  constructor() {
    makeAutoObservable(this);
    let area:any = localStorage.getItem('selectAreas');
    if(area){
      area = JSON.parse(area) as any;
      this.setSelectAreas([...area])
      this.setSearchRoomParmas({
        officeAreaIds:[...area.map((a:any)=>a.id)]
      })
    }
  }

  setShowFilter() {
    this.showFilter = !this.showFilter;
  }
  setLocalRoomId(roomId: string) {
    this.localRoomId = roomId;
  }
  initBookingInfo(){
    const { filterDate,start } = this.searchRoomParmas;
    this.bookingInfo = {
      subject: "",
      roomId: "",
      meetingDate:filterDate ? filterDate : moment().format("YYYY-MM-DD"),
      startTime: start ? start : '',
      duration: 30,
      location: "",
      checkinNow: false,
      areaIds: [],
      ownerInfo: loginUser,
      ownerId: loginUser.id,
      extras:{}
    }
  }
  setSelectAreas(data: any) {
    this.selectAreas = data;
  }

  setBookingInfo(data:any){
    this.bookingInfo ={
      ...this.bookingInfo,
      ...data
    }
  }
  setBusyStatus(status: string) {
    this.busyStatus = status;
  }
  setSearchRoomParmasFreeFilter(data:any){
    this.searchRoomParmas={
      ...this.searchRoomParmas,
      ...data,
      busyType: 'free'
    }
    this.getAllMeetingRoomsWidhtStatus();
  }
  setSearchRoomParmas(data:any){
    this.searchRoomParmas={
      ...this.searchRoomParmas,
      ...data,
      busyType: 'all'
    }
    this.getAllMeetingRoomsWidhtStatus();
  }
  resetSearchRoomParmas(){
    this.searchRoomParmas={
      filterDate: moment().format('YYYY-MM-DD'),
      officeAreaIds: this.searchRoomParmas.officeAreaIds,
        busyType: 'all'
    }
    this.getAllMeetingRoomsWidhtStatus();
  }
  handleAreas(areas: Array<any>): any[] {
    let allAreas = areas.map((item) => {
      return {
        ...item,
        label: item.name,
        value: item.id,
        children: item.children ? this.handleAreas(item.children) : [],
      };
    });
    return allAreas;
  }
  getAreas() {
    return appServer.getAreas().then((res) => {
      let data = this.handleAreas(res);
      this.setAreas(data);
      return data;
    });
  }

  getAllSetting(roomType:string= "ROOM",roomId?:string,isModeify?:boolean,officeAreaId?:string,extraRoomId?:string){
    let filterDate = this.searchRoomParmas.filterDate;
    console.log('getAllSetting officeAreaId=', officeAreaId);
    return appServer.getSeting(roomType,officeAreaId,extraRoomId,roomId,filterDate).then((res) => {
      this.allSetting = res.data || {};
      const { newMeeting ,filterFacilities=[]} = res.data || {};
      this.filterFacilities = filterFacilities
      if(!isModeify){
        if(newMeeting){
          this.bookingInfo ={
            ...this.bookingInfo,
            duration:this.searchRoomParmas.during || newMeeting.duration,
            startTime: this.searchRoomParmas.start ||newMeeting.startTime,
            meetingDate: this.searchRoomParmas.filterDate || newMeeting.meetingDate,
          }
        }
      }

    });
  }

  getMeeting(id:string){
      return appServer.getMeeting(id).then(res=>{
        return res;
      })
  }
  getHistoryMeetings(params: any){
    return appServer.getHistoryMeetings(params).then(res => {
      return res
    })
  }
  finishMeeting(meetingId: string) {
    return appServer.finishMeeting(meetingId);
  }
  cancelMeeting(meetingId: string) {
    return appServer.cancelMeeting(meetingId);
  }
  delayMeeting(meetingId: string,during:number) {
    return appServer.delayMeeting(meetingId, during);
  }

  setShowCalendar() {
    this.showCalendar = !this.showCalendar;
  }
  setNowDate(date: any) {
    this.nowDate = date;
  }
  modifyMeeting(){
    return appServer.modifyMeeting({...this.bookingInfo}).then((res) => {
        return res;
    });
  }
  getRoomInfo(roomId: string) {
    this.bookingInfo.roomId = roomId;
    return appServer.getRoomInfo(roomId).then((res) => {
      this.roomInfo = res;
    });
  }

  getRoomInfoDay(roomId: string, meetingDate: string) {
    this.bookingInfo.roomId = roomId;
    return appServer.getRoomInfoDay(roomId, meetingDate).then((res) => {
      this.roomInfo = res;
    });
  }

  setBookingDur(time: number) {
    this.bookingInfo = {
      ...this.bookingInfo,
      duration: time,
    };
  }
  setAreas(areas: any) {
    this.allAreas = areas;
  }

  getAllMeetingRoomsWidhtStatus() {
    Toast.loading('loading...',10000)
    let params = toJS(this.searchRoomParmas);
    let newParams: any = {
      ...params,
      start:params.start ?  `${params.filterDate} ${params.start}` : `${params.filterDate}`,
      end:  params.during ?  moment(`${params.filterDate} ${params.start}`).clone().add(params.during,'minutes').format('YYYY-MM-DD HH:mm') : `${params.filterDate}`,
      officeAreaIds: params.officeAreaIds || ["all"],
    };

    // 默认全部
    if (newParams.officeAreaIds.length === 0) {
      newParams.officeAreaIds = ["all"];
    }
    return appServer.getAllMeetingRoomsWidhtStatus(newParams).then((res) => {
      let allRoom: any[] = [];
      Toast.hide();
      if (res && res.length) {

        res.forEach(({ rooms }: any) => {
          if (rooms && rooms.length) {
            allRoom = [...allRoom, ...rooms];
          }
        });
      }
      this.allRoomsCount = allRoom.length;
      if(this.searchRoomParmas.includeMeetings === 'false'){
        return res;
      }
      this.allRooms = [...allRoom];
      return res;
    });
  }

  bookingMeeting() {
    if (window.location.href.includes("from=qrcode")) {
      this.bookingInfo = {
        ...this.bookingInfo,
        ownerId:this.bookingInfo.ownerInfo?.id,
        location: this.localRoomId,
      };
    }
    return appServer.formBookingMeeting(this.bookingInfo).then((res) => {
      // router.push(routerHelper.getMobileUrl(`homePage/${this.localRoomId}`));
      if (this.localRoomId) {
        this.getRoomInfo(this.localRoomId);
      }
      return res;
    });
  }
}


const appState = new AppStatus();
export default appState;
