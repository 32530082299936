import React, {Component} from "react";
import {SearchBar, Icon, Checkbox, ActivityIndicator} from "antd-mobile";
import "../style/user.search.scss";
import {observer, inject, Provider} from "mobx-react";
import UserStatus from "../status/index";
import routerHelper from "../../../../utils/helper/router.helper";

// import { appInject } from 'app/app.container';
// import { BookingState } from './booking.state';
// import { ChioceFooterBar } from './components/booking.footer.bar';
// import { IUser } from '../user/user.interface';
// import { urlHelper } from 'app/helper/url.helper';
// import { loginUserState } from 'app/state/login.user.state';
// import { ls } from 'app/locale/locale.state';
// import AddVisitor from 'user/cake/users.picker/add.visitor';
// import { FooterBottom } from '../components/footer.bottom';
// import { MyDepTips } from './components/book.mydep.maintain.tips';
// import classnames from 'classnames';
// import { observable } from 'mobx';
// import { OnChangeParams } from 'antd-mobile/lib/checkbox/PropsType';

/**
 * “选择参会人”页面
 */

interface PersonChoiceStatus {
  searchVal?: string;
  isLoading?: boolean;
  userList?: any[];
}

@inject("userStatus")
@observer
export class PersonChoice extends Component<any, any> {
  private Time:any;
  constructor(props: any) {
    super(props);
    this.onSeachChange = this.onSeachChange.bind(this);
    this.state = {
      searchVal: "",
      isLoading: false,
      userList: [],
    };
  }
  onSeachChange(val: string) {
    const {userStatus} = this.props;
    this.setState({isLoading: true});
    this.setState({searchVal: val});
    if(this.Time){
      clearTimeout(this.Time);
    }
    this.Time = setTimeout(() =>{
      userStatus.getUserList(val).then((res: any) => {
        this.setState({
          isLoading: false,
          userList: [...res],
        });
      });
    },500)
  }
  selectUsers(user: any) {
    const {userStatus, history} = this.props;
    userStatus.setSelectUser(user);
    let path = routerHelper.getMobileUrl("selectuser");
    history.push(path);
  }
  render() {
    const {searchVal, isLoading, userList} = this.state;
    const {userStatus} = this.props;
    const {selectModel} = userStatus || {};
    return (
      <div className="choice-person-content">
        <div className="choice-persion-seach">
          <SearchBar
            placeholder={"请输入名称..."}
            onChange={this.onSeachChange}
            value={searchVal}
            cancelText={"取消"}
            // onFocus={this.onSearchStar}
            // onCancel={this.searchCancal}
            // onBlur={this.onBlurFocus}
          />
        </div>

        <div className="choice-person-item-content">
          {userList &&
            userList.length > 0 &&
            userList.map((user: any) => {
              return (
                <div
                  key={user.id}
                  className="choice-persion-info"
                  onClick={() => {
                    this.selectUsers(user);
                  }}
                >
                  <dl>
                    {selectModel === "Single" ? (
                      <dt/>
                    ) : (
                      <dt>
                        <div className="persion-checkbox">
                          <Checkbox
                            // disabled={loginUserState.loginUser.id === user.id}
                            checked={false}
                          />
                        </div>
                      </dt>
                    )}

                    <dd>
                      <p className="persion-name">{user.name}</p>
                      <p className="persion-dept">
                        {user.deptName} {user.email}
                      </p>
                    </dd>
                  </dl>
                </div>
              );
            })}
        </div>
        <ActivityIndicator toast={true} animating={isLoading} />
      </div>
    );
  }
}
export default (props: any) => {
  return (
    <Provider userStatus={UserStatus}>
      <PersonChoice {...props} />
    </Provider>
  );
};
