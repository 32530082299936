import React from 'react';
import './styles/index.scss';
import classnames from 'classnames';
interface MobileModelProps{
    visibility?:boolean;
    title?:string;
    onClose?:() => void;
    onReset?:() => void;
    onSuer?:() => void;
    onResetText?:string;
    btnText?:string;
}


class MobileModel extends React.Component<MobileModelProps>{
    render(){
        const {visibility,onResetText,children, onClose,onReset,onSuer,title,btnText } = this.props;
        return(
            <div 
                className={classnames('model-content',{
                    'model-active':visibility
                })}
            >
                <div className="model-content-header">
                    <div 
                        className="model-content-header-close"
                        onClick={(e)=>{
                            e.stopPropagation();
                            onClose && onClose()
                        }}
                    >
                        <span className="iconfont icon-_delete-big"/>
                    </div>
                    <div className="model-content-header-title">
                            {title}
                    </div>
                    <div 
                        className="model-content-header-tips"
                        onClick={(e)=>{
                            e.stopPropagation();
                            onReset && onReset();
                        }}
                    >
                        {onResetText}
                    </div>
                </div>
                <div className="model-content-center">
                        {children}
                </div>
                <div className="model-content-footer">
                    <div 
                        className="model-content-footer-btn"
                        onClick={(e)=>{
                            e.stopPropagation();
                            onSuer && onSuer()
                        }}
                    >
                        {btnText ? btnText :'提交 '}
                    </div>
                </div>
            </div>
        );
    }
}

export default MobileModel;