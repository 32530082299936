import React from "react";

interface RenderServerItemProps {
  label: string;
  showValue: string;
  value: any;
  valueType: string;
}

function RenderServerItem(item: RenderServerItemProps) {
  const {label, value, valueType} = item;
  return (
    <div className="detial-page-place">
      <h3>{label}</h3>
      <div className="detial-page-server">
        {valueType === "CHECKBOX" &&
          value &&
          !!value.length &&
          value.map((a: string) => {
            return <p className="active" key={`${label}_${a}`}>{a} </p>;
          })}

        {valueType === "RADIO" && value && <p className="active">{value} </p>}
      </div>
    </div>
  );
}
export default RenderServerItem;
