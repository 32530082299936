import './styles/index.scss';

import { Provider, inject, observer } from "mobx-react";

import { Modal } from 'antd-mobile';
import React from "react";
import RenderServerItem from '../Components/ServerItem/RenderServerItem';
import appState from "../appStatus";
import  moment  from 'moment';
import routerHelper from "../../utils/helper/router.helper";

moment.locale('zh-cn');
const alert = Modal.alert;

interface HomeDetialStatus {
  Meeting:any
}

@inject('store')
@observer
class MeettingDetial extends React.Component<any, HomeDetialStatus> {
  constructor(props: any) {
    super(props);
    this.state = {
      Meeting:{}
    }
  }

  renderDate(date:moment.Moment){
    if(moment().isSame(date,'day')){
        return '今天';
    }
    if(moment().add(1,'day').isSame(date,'day')){
        return '明天';
    }
    return moment(date).clone().format('ddd')
}
componentDidMount(){
  const { match } = this.props;
  const { store } = this.props;

  store.getMeeting(match.params.meetingId).then((res:any)=>{
    console.log('res',res)
    let { roomId } = res || {}
    if(roomId){
      store.getRoomInfo(roomId);
    }
    this.setState({
      Meeting:res
    })

  });

}

  renderServer(extras?:any){
    let data:any = [];
    let serverData:any = []
    if(extras){
      data = Object.keys(extras)
      data.map((a:any)=>{
        serverData.push(extras[a])
      })
    }
    return serverData;
  }

  render() {
   const { Meeting:meetingInfo } = this.state;
   const { abilities = [] ,checkedStatus } = meetingInfo || {};
   const { roomInfo = {} } = this.props.store || {};
   let  renderServer = this.renderServer(meetingInfo.extras);
   return (
      <div className="detial-page-content">
            <div className="detial-page-header">
              <div className="detial-page-info">
                      <h3>{roomInfo.roomName}</h3>
                      <p> {roomInfo.capacity ? roomInfo.capacity : 10} 个座位 </p>
              </div>
              <div className="detial-page-img">
                {roomInfo.defaultImageUrl ? <img src={roomInfo.defaultImageUrl} alt="图片"/> :
                   <img src={require('../../assent/images/demo.png')} alt="暂无图片"/>}
              </div>
            </div>
            <div className="detial-page-place">
                <h3>会议主题</h3>
                <p>{meetingInfo.subject}</p>
            </div>

            <div className="detial-page-place">
                <h3>会议时间</h3>
                <p>{moment(meetingInfo.beginTime).format('yyyy-MM-DD dddd HH:mm')} -{moment(meetingInfo.endTime).format('HH:mm')} </p>
            </div>
            <div className="detial-page-place">
                <h3>会议主持人</h3>
                <p>{meetingInfo.ownerName} - {meetingInfo.ownerDeptName}</p>
            </div>
            {roomInfo.officeAreaAddress &&
            <div className="detial-page-place">
                <h3>会议室地址</h3>
                <p>{this.props.store.roomInfo.officeAreaAddress}</p>
            </div>
            }
            {abilities.includes('show-checkin') &&
            <div className="detial-page-place">
                <h3>是否签到</h3>
                {checkedStatus === 'Y' && <p className="isChecked">已签到</p>}
                {checkedStatus === 'N' && <p className="unChecked">未签到</p>}
            </div>}
            {renderServer && renderServer.map((a:any,index:number)=>{
              if(!a.value){return <></>}
              return(<RenderServerItem {...a} key={index}/>);
            })}
            <div className="detial-page-place">
                <h3>取消预订规则</h3>
                <p>会议开始前可随时取消预订，或联系管理员取消；会议开始后，只能提前结束会议。</p>
            </div>
            <div className="detial-page-place"><h3/><p/></div>

              <div className="detial-page-footer">
              {abilities.includes("edit") &&
                  <div
                    className="detial-page-footer-btn"
                    onClick={(e)=>{
                      e.stopPropagation();
                      const { match,history } = this.props;
                      let path = routerHelper.getMobileUrl(`meetingModify/${match.params.meetingId}`);
                      history.push(path);
                    }
                  }
                  >
                      修改
                  </div>
                }
                {abilities.includes("cancel") &&
                  <div
                    className="detial-page-footer-btn btn-warn"
                    onClick={(e)=>{
                      e.stopPropagation();
                      const { match } = this.props;
                      const { store } = this.props;
                      alert('取消会议', '确定取消会议?', [
                        { text: '取消', onPress: () => console.log('cancel') },
                        { text: '确定', onPress: () => {
                          store.cancelMeeting(match.params.meetingId).then((res:any)=>{
                            store.getMeeting(match.params.meetingId).then((res:any)=>{
                              this.setState({
                                Meeting: res
                              });
                              const { match,history } = this.props;
                              let path = routerHelper.getMobileUrl(`homePage`);
                              history.push(path);
                            });
                          })
                        } },
                      ])
                    }}
                  >
                      取消预订
                  </div>
         }
         {abilities.includes("close") &&
            <div
              className="detial-page-footer-btn btn-warn"
              onClick={(e) => {
                e.stopPropagation();
                const { match } = this.props;
                const { store } = this.props;
                alert('提前结束', '确定提前结束会议?', [
                  { text: '取消', onPress: () => console.log('cancel') },
                  {
                    text: '确定', onPress: () => {
                      store.finishMeeting(match.params.meetingId).then((res: any) => {
                        store.getMeeting(match.params.meetingId).then((res: any) => {
                          this.setState({
                            Meeting: res
                          })
                          const { match, history } = this.props;
                          let path = routerHelper.getMobileUrl(`homePage`);
                          history.push(path);
                        });
                      })
                    }
                  },
                ])
              }}
            >
              提前结束
                  </div>
          }
              </div>
       </div>
    );
  }
}

export default (props: any) => {
  return (
    <Provider store={appState}>
      <MeettingDetial {...props} />
    </Provider>
  );
};

{/* {abilities.includes("edit") && */}
