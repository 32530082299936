import React from "react";
import routerHelper from "../../../utils/helper/router.helper";
import SetPublicPerson from "./SetUser";
import PersonChoice from "./SearchUser";
import { Route } from "react-router-dom";

class SelectUser extends React.Component {
  render() {
    return (
      <>
        <Route
          exact
          path={routerHelper.getMobileUrl(`selectuser/searchUser`)}
          name="searchUser"
          component={PersonChoice}
        />
        <Route
          exact
          path={routerHelper.getMobileUrl(`selectuser`)}
          name="setuser"
          component={SetPublicPerson}
        />
      </>
    );
  }
}
export default SelectUser;
