import React from "react";
import { Link } from "react-router-dom";
import appState from "../appStatus";
import { observer, inject, Provider } from "mobx-react";
import './styles/areaChoice.scss';


@inject('store')
@observer
class OtherArea extends React.Component<any> {
    constructor(props: any) {
        super(props);
    }

    handleAreaClick(id: string): void {
        const { history } = this.props;
        appState.bookingInfo.currentAreaId = id
        history.goBack()
    }
    render() {
        const { store } = this.props;
        const otherArea: any[] =store.allAreas
        return (
            <div className="area-choice-content">
                <div className="areas">
                    {
                        otherArea.map((item,index) =>
                            <div className="area" key={index} onClick={() => this.handleAreaClick(item.id)}>
                                <span className="area-name">{item.name}</span>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default (props: any) => {
    return (
        <Provider store={appState}>
            <OtherArea {...props} />
        </Provider>
    );
};

