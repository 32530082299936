import React from 'react';
import './styles/index.scss';
import { Calendar } from 'antd-mobile';
import enUS from 'antd-mobile/lib/calendar/locale/en_US';
import zhCN from 'antd-mobile/lib/calendar/locale/zh_CN';
import moment from 'moment';
import classnames from 'classnames';
moment.locale('zh-cn');         // zh-cn

interface HeaderCalendarProps {
    onChange?: (date: moment.Moment) => void;
    defaultDate?: moment.Moment;
    maxDay?:number;
}

interface HeaderCalendarStatus {
    showCalendar: boolean;
    nowDate: moment.Moment;
    firstDay: moment.Moment;

    
}

class HeaderCalendar extends React.Component<HeaderCalendarProps, HeaderCalendarStatus>{
    constructor(props: any) {
        super(props);
        this.state = {
            showCalendar: false,
            nowDate: props.defaultDate || moment(),
            firstDay: props.defaultDate || moment()
        }
    }

    renderDate(date: moment.Moment) {
        if (moment().isSame(date, 'day')) {
            return '今天';
        }
        if (moment().add(1, 'day').isSame(date, 'day')) {
            return '明天';
        }
        return moment(date).clone().format('dd')
    }
    render() {
        const { showCalendar, nowDate, firstDay } = this.state;
        const { onChange ,maxDay} = this.props;
        let dates = Array.from({ length: 15 }, (v, k) => k);
        return (
            <div className="header-calendar-wrap">
                <div className="header-calendar">
                    <div className={'header-calendar-content'}>
                        {
                            dates.map(day => {
                                let theDay = moment(firstDay).clone().add(day, 'day');
                                return (
                                    <p
                                        className={
                                            classnames('calendar-content-item', {
                                                'calendar-active': moment(nowDate).isSame(theDay, 'day')
                                            })
                                        }
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onChange && onChange(theDay)
                                            this.setState({ nowDate: theDay });
                                        }}
                                        key={day}
                                    >
                                        <dt>{this.renderDate(theDay)}</dt>
                                        <dd>{theDay.format('M/D')}</dd>
                                    </p>
                                );
                            })
                        }

                    </div>

                </div>
                <div
                    className="header-calendar-icon"
                    onClick={(e) => {
                        e.stopPropagation();
                        this.setState({ showCalendar: !showCalendar })
                    }}
                >
                    <span className="iconfont icon-rili" />
                </div>
                <Calendar
                    visible={showCalendar}
                    onCancel={() => {
                        this.setState({ showCalendar: !showCalendar })
                    }}
                    onConfirm={(date: any) => {
                        onChange && onChange(moment(date))
                        this.setState({ nowDate: moment(date), showCalendar: !showCalendar, firstDay: moment(date) });
                    }}
                    defaultValue={[moment(nowDate).toDate(), moment(nowDate).toDate()]}
                    locale={zhCN}
                    type={'one'}
                    minDate={ moment().toDate()}
                    maxDate={ maxDay ?   moment().add(maxDay,'days').toDate() : undefined }
                />
            </div>
        );
    }

}

export default HeaderCalendar;
