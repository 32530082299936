

class RouterHelper {
  projectName: string =  projectName || "mobile-v6";
  getMobileUrl(path: string) {
    return `/mobile/${this.projectName}/${path}`;
  }
  getDeskTopUrl(path: string) {
    return `/mobile/${this.projectName}/${path}`;
  }
}

const routerHelper = new RouterHelper();

export default routerHelper;
