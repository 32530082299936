import React, { Component } from 'react';
import TipSattus from '../../TipSattus';
import UserStatus from '../status';
import PersonItem from '../compoments/UserItem';
import { observer, inject, Provider } from "mobx-react";
import './style/index.css';

// 外部依赖状态
import appState from '../../../appStatus';
import routerHelper from '../../../../utils/helper/router.helper';

interface Istate {
    timeSelect: Array<any>;
}

@inject('userStatus','store')
@observer
export class SetPublicPerson extends Component<any, Istate> {
    // 这边处理人数 所选人数
    constructor(props: any) {
        super(props);
    }

    render() {
        const { userStatus,store} = this.props;

        const { selectUser,modify} = userStatus || {};
        const { bookingInfo } = store || {};
        return (
            <div className="set-person-content">
                <div
                    className="set-person-header"
                    onClick={(e) => {
                        e.stopPropagation();
                        let path = routerHelper.getMobileUrl('selectuser/searchUser');
                        const { history } = this.props;
                        history.push(path)
                    }}
                >
                    <p>修改预订人</p>
                    <span className="iconfont icon-right" />
                </div>
                <div className="form-tips">
                    <TipSattus conflict={'1'} /> 忙碌
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TipSattus conflict={'0'} /> 空闲
                </div>
                <div className="set-persion-allitems">
                {selectUser.map((a:any)=>(
                        <PersonItem
                            isChecked={true}
                            user={a}
                        />
                ))}

                </div>
                <div className="set-persion-btn">
                    <p
                        onClick={(e) => {
                            e.stopPropagation();
                            const { roomId } = bookingInfo || {}
                            let path =  routerHelper.getMobileUrl(`homeDetial/${roomId}`);
                            this.props.history.push(path);
                        }}
                    >
                        取消
                    </p>
                    <p
                        className="btn-active"
                        onClick={(e) => {
                            e.stopPropagation();
                            const { history } = this.props;
                            const [ ownerInfo ] = selectUser;
                            const { roomId,id } = bookingInfo || {};
                            let path =  routerHelper.getMobileUrl(`homeDetial/${roomId}`);
                            if(id){
                                path = routerHelper.getMobileUrl(`meetingModify/${id}`);
                            }
                            store.setBookingInfo({ownerInfo, ownerId: ownerInfo.id});
                            history.push(path);
                        }}
                    >
                        确认
                    </p>
                </div>
            </div>
        );

    }
}

export default (props: any) => {
    return (
      <Provider store={appState} userStatus={UserStatus}>
        <SetPublicPerson {...props} />
      </Provider>
    );
  };
