import moment from "moment";

export const disableTimes = [[0,0]]; // 禁用0-0点, 必须有值，无值会出错.

export class Utils<UtilStats> {
  name: string = "";
  timeChangeNumber(start: moment.Moment, dur: number) {
    let startTime = moment(start).format("H");
    let startMin = Number(moment(start).format("m")) / 60;
    let startNum = Number(startTime) + startMin;
    let endNum = startNum + dur / 60;
    return [startNum, endNum];
  }
  numberChangeToTime(time: Array<any>) {
    let [start, end] = time;
    console.log("start=", start, ", end=", end);
    if (start > 24 || end > 24) {
      start = 23;
      end = 24;
    }
    let dur = (end - start) * 60;
    let startH =
      Math.floor(start) > 9 ? `${Math.floor(start)}` : `0${Math.floor(start)}`;
    let startV = ((start % 1) * 60).toFixed(0);
    let startM = (start % 1) * 60 > 10 ? `${startV}` : `0${startV}`;
    return {
      time: `${startH}:${startM}`,
      dur,
    };
  }
  renderDisableTime(meetings: Array<any>,meetingId?:string) {
    let disableTime:any[] = [];
    meetings && meetings.map((data: any) => {
      let {beginTime, duration} = data || {};
      if (beginTime && duration) {
        let [start,end] = this.timeChangeNumber(moment(beginTime), duration);
        let item = {
          ...data,
          start,
          end,
          backgroundColor:"rgba(253, 148, 10, 1)"
        }
        if(!meetingId){
          disableTime.push(item);
        } else  {
          if(data.id !== meetingId){
            disableTime.push(item);
          }
        }
        
      }
    });
    return disableTime;
  }

  round(number: number, precision: number = 2) {
    return Math.round(+(number + "e" + precision)) / 10 ** precision;
  }
}
const utils = new Utils();
export default utils;
