import './styles/areaChoice.scss';

import { Provider, inject, observer } from "mobx-react";

import React from "react";
import appState from "../appStatus";
import radio from "../areaChoice/img/radio.png"
import radio1 from "../areaChoice/img/radio1.png"
import routerHelper from '../../utils/helper/router.helper';
import { toJS } from "mobx";

interface ScanningHomePageStatus {
    allAreas: any[],
    name: string,
    id: string,
    area: any
}

@inject('store')
@observer
class AreaChoice extends React.Component<any, ScanningHomePageStatus> {
    constructor(props: any) {
        super(props);
        console.log('AreaChoice:', props);
        this.state = {
            allAreas: [], // 所有地区
            name: '',
            id: '',
            area: {}
        }
    }

    get isAllChoice() {
        const allAreas = this.state.allAreas || []
        for (let x of allAreas) {
            if (!x.checked) return false
        }
        return true;
    }

    componentDidMount(): void {
        appState.getAreas().then(res => {
            let data = this._getCurrentArea();
            console.log('data=', data);
            this.setState({
                allAreas: data.children,
                name: data.name,
                id: data.id,
                area: data
            })
        })
    }

    _getCurrentArea() {
        let selectAreas: any = localStorage.getItem('selectAreas');
        selectAreas = JSON.parse(selectAreas);
        const { store } = this.props;
        let data: any = store.allAreas.find((x: any) => x.id === store.bookingInfo.currentAreaId);

        if (!data) {
            if (selectAreas && selectAreas.length) {
                let { parentId } = selectAreas[0];
                data = store.allAreas.find((x: any) => x.id === parentId);
                if (data) {
                    let { children } = data || {};
                    children = children.map((a: any) => {
                        let nowData = selectAreas.find((x: any) => x.id === a.id);
                        if (nowData) {
                            return nowData
                        }
                        return a;
                    })
                    data = {
                        ...data,
                        children
                    }
                }
            }
        }
        return data || {};
    }


    render() {
        const isAllChoice = this.isAllChoice;
        const allAreas: any[] = this.state.allAreas || [];
        // console.log('allAreas=', allAreas);
        const currentId = this.state.id;
        const currentName = this.state.name;
        const currentArea = this.state.area;
        console.log('currentName=', currentName);
        return (
            <div className="area-choice-content">
                <div className="title">
                    <span className="city-name">{this.state.name}</span>
                    <span
                        className="choice-area"
                        onClick={(e) => {
                            e.stopPropagation()
                            const { history } = this.props
                            let path = routerHelper.getMobileUrl(`otherArea`);
                            history.push(path);
                        }}
                    >切换其他地区</span>
                </div>
                <div className="areas">
                    <div className="area">
                        {allAreas.length > 0 ? <span className="area-name">共{allAreas.length}个地区</span> :
                            <span className="area-name">全部区域</span>
                        }
                        {
                            allAreas.length ?
                                <div className="all-choice">
                                    <img
                                        className="radio"
                                        src={isAllChoice ? radio : radio1}
                                        onClick={() => {
                                            allAreas.forEach(x => x.checked = !isAllChoice)
                                            this.setState({
                                                allAreas: allAreas,
                                            })
                                        }} />
                                    <span className="text">{isAllChoice ? '全不选' : '全选'}</span>
                                </div>
                                :
                                (currentName && currentName !== '') ?
                                    <div className="all-choice">
                                        <img
                                            className="radio"
                                            src={isAllChoice ? radio : radio1}
                                            onClick={() => {
                                                this.setState({
                                                    allAreas: [this.state.id],
                                                })
                                            }} />
                                    </div>
                                    :
                                    null
                                        }
                    </div>
                    {
                        allAreas.map((item, index) =>
                            <div className="area" key={index}>
                                <span className="area-name">{item.name}</span>
                                <div className="all-choice">
                                    <img
                                        className="radio"
                                        src={item.checked ? radio : radio1}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            allAreas[index].checked = !item.checked
                                            this.setState({ allAreas })
                                        }}
                                    />
                                </div>

                            </div>
                        )
                    }
                </div>
                <div
                    className="button"
                    onClick={(e) => {
                        e.stopPropagation();
                        const { store } = this.props;
                        let officeAreaIds = allAreas.filter(x => x.checked).map(x => x.id);
                        let selectAreas = allAreas.filter(x => x.checked).map(x => x);

                        if (currentName === undefined) {
                            console.log('do nothing');
                            return;
                        }

                        if (officeAreaIds.length === 0) {
                            officeAreaIds = [currentId];
                            selectAreas = [currentArea];
                        }

                        store.setSelectAreas(toJS(selectAreas))
                        // console.log('isAllChoice',isAllChoice)
                        localStorage.setItem('selectAreas', JSON.stringify(selectAreas));
                        localStorage.setItem('isAllChoice', JSON.stringify({ isAllChoice }));
                        store.setSearchRoomParmas({
                            officeAreaIds: officeAreaIds
                        })
                        // console.log('select -> officeAreaIds:', officeAreaIds);
                        localStorage.setItem('officeAreaIds', officeAreaIds.join(','));
                        const { history } = this.props;
                        history.goBack()
                    }}
                >搜索会议室
                </div>
            </div>
        );
    }
}

export default (props: any) => {
    return (
        <Provider store={appState}>
            <AreaChoice {...props} />
        </Provider>
    );
};
