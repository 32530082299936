import React from "react";
import classnames from 'classnames';
interface RadioItemProps {
    showName: string;
    hint: string;
    code: string;
    valueScope: Array<string>;
    onChange: (val: any) => void;
    value?: string;
}

class RadioItem extends React.Component<RadioItemProps> {


    render() {
        const { showName, hint, code, valueScope, value, onChange } = this.props;
        return (
            <div className="detial-page-place">
                <h3>{showName} {hint &&
                    <small>({hint})</small>
                }
                </h3>
                <div className="detial-page-server">
                    {valueScope.map(a => {
                        return (
                            <p
                                className={classnames({
                                    active: value === a
                                })}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    let data: any = {};
                                    if (a === value) {
                                        data[code] = {
                                            value: ''
                                        }
                                    } else {
                                        data[code] = {
                                            value: a
                                        }
                                    }
                                    onChange({ ...data })
                                }}
                            >
                                {a}
                            </p>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default RadioItem;
