import * as React from "react";
import {Checkbox} from "antd-mobile";
import TipSattus from '../../TipSattus';
import '../style/user.item.scss';

interface PersonItemStatus{
    user:any;
    isChecked?:boolean;
    disabled?:boolean;
    onChange?:(id:string) => void;
}

 class PersonItem extends React.Component<PersonItemStatus, {}> {
  constructor(props: any) {
    super(props);
  }
  render() {
    const { user, isChecked, disabled ,onChange} = this.props;
    return (
      <div
        className="choice-persion-info"
        key={user.id}
        onClick={(e) => {
          e.stopPropagation();
          onChange && onChange(user.id);
        }}
      >
        <dl>
          {/* <dt>
            <div className="persion-checkbox">
              <Checkbox
                disabled={disabled}
                checked={isChecked}
              />
            </div>
          </dt> */}
          <dd>
            <p className="persion-name">
              <TipSattus conflict={user.conflict} /> {user.name}
            </p>
            <p className="persion-name">
              <span>{user.email ? user.email : "无邮箱"}</span>
            </p>
            <p className="persion-dept">
                {user.deptName}
            </p>
          </dd>
        </dl>
      </div>
    );
  }
};
export default PersonItem;
