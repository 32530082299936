import React from "react";
import appState from "../appStatus";
import UserStatus from '../Components/SelectPersion/status';
import { observer, inject, Provider } from "mobx-react";
import './styles/index.scss';
import { Calendar, TextareaItem, Toast, Modal } from 'antd-mobile';
import moment from 'moment';
import { toJS } from 'mobx';
import zhCN from 'antd-mobile/lib/calendar/locale/zh_CN';
import classnames from 'classnames'
import routerHelper from '../../utils/helper/router.helper';
import TimeRangeSelector from 'react-time-range-selector';
import utils, { disableTimes } from '../../utils';
import ServerItem from '../Components/ServerItem';
import BootFooter from '../Components/BottomFooter';
moment.locale('zh-cn');

interface HomeDetialStatus {
  showCalendar: boolean;
  meetingDetail: any;
  meetingVisible: boolean;
  initLeftData: number;
  isOnChange:boolean;
  //   nowDate: any;
  //   roomId?: string;
}

@inject('store', 'userStatus')
@observer
class HomeDetial extends React.Component<any, HomeDetialStatus> {
  private timeSet :any;
  constructor(props: any) {
    super(props);
    const { match, store } = this.props;
    appState.getRoomInfoDay(match.params.roomId, store.bookingInfo.meetingDate);

    this.handleSumit = this.handleSumit.bind(this);
    this.hasMeetting = this.hasMeetting.bind(this);
    this.getAllServers = this.getAllServers.bind(this);
    this.state = {
      showCalendar: false,
      meetingDetail: {} as any,
      meetingVisible: false,
      initLeftData: 0,
      isOnChange:false
    }
  }


  renderDate(date: moment.Moment) {
    if (moment().isSame(date, 'day')) {
      return '今天';
    }
    if (moment().add(1, 'day').isSame(date, 'day')) {
      return '明天';
    }
    return moment(date).clone().format('ddd')
  }



  handleSumit() {
    const { store } = this.props;
    let { bookingInfo } = store;
    const { subject } = bookingInfo || {};
    if (!subject) {
      Toast.fail('会议主题不存在！', 1)
      return;
    }
    this.props.store.bookingMeeting().then((res: any) => {
      const { warnMessage } = res;
      if (warnMessage) {
        return;
      }
      const { match, history, store } = this.props;
      store.initBookingInfo();
      let path = routerHelper.getMobileUrl(`bookingDetial/${res.id}`)
      appState.getRoomInfo(match.params.roomId);
      history.push(path);
    });
  }

  hasMeetting(meetings: any[]) {
    let hasMeeting = meetings.find((item) => item.abilities.includes("ongoing"));
    if (hasMeeting) {
      return hasMeeting;
    } else {
      return undefined;
    }
  }

  showMeeting(meeting: any) {
    this.setState({
      meetingDetail: meeting,
      meetingVisible: true
    })
  }

  handleMeetingClose() {
    this.setState({ meetingVisible: false })
  }

  handleContainerClick = (val: number) => {


    // 防止onChange事件事 也执行
    if(this.state.isOnChange){
      return;
    }
    const { store } = this.props;
    let { roomInfo = {}, bookingInfo, } = store;
    const { meetings = [] } = roomInfo || {};
    let time = utils.timeChangeNumber(moment(`${bookingInfo.meetingDate} ${bookingInfo.startTime}`), bookingInfo.duration)
    const [start, end] = time;
    let dur =  val + end - start;
    // if (start < val && val < end) {
    //   return
    // }

    for (let i = 0; i < meetings.length; i++) {
      const meeting = meetings[i]
      let { beginTime, duration } = meeting || {};
      if (beginTime && duration) {
        let time = utils.timeChangeNumber(moment(beginTime), duration)
        if (time[0] <= val && val < time[1]) {
          this.showMeeting(meeting)
          return;
        }
      }
    }

    if (disableTimes.some(x => x[0] < val && val < x[1])) {
      return;
    }
    const val2 = [val, dur]
    let timeDate = utils.numberChangeToTime(val2)
    let data = {
      startTime: timeDate.time,
      duration: timeDate.dur
    }
    this.props.store.setBookingInfo({ ...data });
  }

  getAllServers() {
    const { store } = this.props;
    let { allSetting } = store;
    let meetingServer: Array<any> = [];
    let bookingServices = allSetting && allSetting.roomTypeCustomForms.filter((form: any) => form.resourceType === 'ROOM' && form.fields);
    if (bookingServices && bookingServices.length > 0) {
      for (let i = 0; i < bookingServices.length; i++) {
        if (bookingServices[i].fields) {
          bookingServices[i].fields.forEach((value: any) => {
            meetingServer.push(toJS(value));
          });
        }
      }
    }
    return meetingServer;
  }
  componentDidMount() {
    this.props.store.getAllSetting('ROOM', this.props.match.params.roomId).then((res:any)=>{
      const { store } = this.props;
      let { bookingInfo } = store;
      let bookingStartTime = store.bookingInfo.startTime;
      let time = utils.timeChangeNumber(moment(`${bookingInfo.meetingDate} ${bookingStartTime}`), bookingInfo.duration)
      const [start, end] = time;
      if (Math.floor(start) > this.state.initLeftData) {
        this.setState({
          initLeftData: Math.floor(start),
        })
      }
    })


  }
  render() {
    const { store, userStatus } = this.props;
    let { roomInfo = {}, bookingInfo, filterFacilities, allSetting } = store;
    const { meetingTimes, newMeeting } = allSetting || {};
    const { ownerInfo, subject, extras } = bookingInfo || {};
    let bookingStartTime = bookingInfo.startTime;
    const { meetingDetail, meetingVisible } = this.state;
    let time = utils.timeChangeNumber(moment(`${bookingInfo.meetingDate} ${bookingStartTime}`), bookingInfo.duration)
    const [start, end] = time;
    roomInfo = toJS(roomInfo)
    filterFacilities = toJS(filterFacilities)
    const { meetings = [], officeAreaAddress, defaultImageUrl, facilities = [] } = roomInfo || {};
    const disableTime = utils.renderDisableTime(meetings);
    let meetingServer = this.getAllServers();
    const isMobile: boolean = (window as any).isMobile
    const { bookingLimitDays } = meetingTimes || {}
    const { initLeftData } = this.state;

    return (
      <div
        className="detial-page-content"
        onClick={(e) => {
          e.stopPropagation();
          this.setState({
            meetingDetail: undefined,
            meetingVisible: false
          })

        }}
      >
        <div className="detial-page-img">
          {defaultImageUrl ? (
            <img src={defaultImageUrl} alt="图片" />
          ) : (
              <img src={require("../../assent/images/demo.png")} alt="暂无图片" />
            )}
        </div>

        <div className="detial-page-info">
          <h3>{roomInfo.name}</h3>
          <p>
            {" "}
            {roomInfo.capacity && roomInfo.capacity + "个座位"}{" "}
            {filterFacilities.map((a: any) => {
              if (facilities.includes(a.key)) {
                return `| ${a.value}`;
              }
            })}{" "}
          </p>
        </div>
        {meetingDetail && meetingVisible && (
          <div className="meeting-detial-des">
            <span>
              {moment(meetingDetail.beginTime).format("HH:mm")} -
              {moment(meetingDetail.beginTime)
                .clone()
                .add(meetingDetail.duration, "minutes")
                .format("HH:mm")}
            </span>
            <span>{this.state.meetingDetail?.ownerName}</span>
            <span> {this.state.meetingDetail?.ownerDeptName}</span>
            <span>{this.state.meetingDetail?.ownerMobile ? <a href={`tel:${this.state.meetingDetail?.ownerMobile}`}>{this.state.meetingDetail?.ownerMobile}</a> : '暂无手机'}</span>
          </div>
        )}
        { !isMobile && !meetingVisible && (
          <>
          <div className="meeting-detial-des tips">
            <span>使用 shift + 滚轮 滑动时间轴</span>
          </div>
          <div className="meeting-detial-des tips">
            <span>使用 shift + 点击 定位时间轴</span>
          </div>
          </>
        )}
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <TimeRangeSelector
            height={50}
            isMobile={isMobile}
            // initialScrollLeft={typeof start === "number" ? start * 100: (new Date()).getHours() * 100}
            scrollLeft={
              typeof initLeftData === "number"
                ? initLeftData * 100
                : new Date().getHours() * 100
            }
            value={[start, end]}
            disableBoxBorderWidth={2}
            range={"day"}
            splitWidth={100}
            snap={0.25}
            onContainClick={this.handleContainerClick}
            disabledTimeRanges={[...disableTime] as any}
            onChange={(val: any) => {
              if(this.timeSet){
                clearTimeout(this.timeSet);
              }
              let timeDate = utils.numberChangeToTime(val);
              this.setState({isOnChange:true })
              let data = {
                startTime: timeDate.time,
                duration: timeDate.dur,
              };
              this.props.store.setBookingInfo({ ...data });
              this.timeSet = setTimeout(()=>{
                this.setState({isOnChange:false })
              }, 200)
            }}
            onDisableclick={(val)=>{
              if(this.timeSet){
                clearTimeout(this.timeSet);
              }
              this.setState({isOnChange:true })
               this.showMeeting(val);
               this.timeSet = setTimeout(()=>{
                this.setState({isOnChange:false })
              }, 200)
            }}
          />
        </div>
        <div className="detial-page-time">
          <div
            className={classnames("detial-page-time-reduce", {
              active: bookingInfo.duration - 15 > 0,
            })}
            onClick={(e) => {
              e.stopPropagation();
              if (bookingInfo.duration <= 15) {
                return;
              }
              let data = {
                duration: bookingInfo.duration - 15,
              };
              this.props.store.setBookingInfo({ ...data });
            }}
          >
            <span className="iconfont icon-iconset0187 iconAdd" />
          </div>
          <div className="detial-page-time-center">
            {bookingInfo.startTime} -{" "}
            {moment(`${bookingInfo.meetingDate} ${bookingInfo.startTime}`)
              .add(bookingInfo.duration, "minutes")
              .format("HH:mm")}
            ,{bookingInfo.duration} 分钟
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              let data = {
                duration: bookingInfo.duration + 15,
              };
              this.props.store.setBookingInfo({ ...data });
            }}
            className="detial-page-time-add active"
          >
            <span className="iconfont icon-add1 iconAdd" />
          </div>
        </div>

        <div className="detial-page-date">
          <h3>日期</h3>
          <div
            onClick={(e) => {
              e.stopPropagation();
              this.setState({ showCalendar: !this.state.showCalendar });
            }}
          >
            {moment(bookingInfo.meetingDate).format("YYYY-MM-DD")}&nbsp;
            {this.renderDate(bookingInfo.meetingDate)}
            <span className="iconfont icon-right iconrignt" />
          </div>
        </div>
        <div className="detial-page-place">
          <div className="detial-page-place-p">
            <TextareaItem
              autoHeight
              rows={2}
              value={subject}
              placeholder="请输入主题 必填"
              color={"#999"}
              onChange={(val: any) => {
                store.setBookingInfo({
                  subject: val,
                });
              }}
              maxLength={200}
            />
          </div>
        </div>
        <div
          className="detial-page-place"
          onClick={(e) => {
            e.stopPropagation();
            userStatus.initUserSeletStatus("Single", [ownerInfo]);
            let path = routerHelper.getMobileUrl("selectuser");
            const { history } = this.props;
            history.push(path);
          }}
        >
          <h3>会议主持人</h3>
          <p className="detial-page-place-p">
            {ownerInfo
              ? `${ownerInfo.name}-${ownerInfo.deptName}`
              : "请选择人员"}
            <span className="iconfont icon-right float-icon-right" />
          </p>
        </div>
        {officeAreaAddress && (
          <div className="detial-page-place">
            <h3>会议室地址</h3>
            <p className="detial-page-place-p">{officeAreaAddress}</p>
          </div>
        )}

        {meetingServer.map((a: any, index: number) => {
          let newItem = {
            ...a,
            value: extras[a.code]?.value,
            onChange: (val: any) => {
              let data = {
                extras: { ...extras, ...val },
              };
              store.setBookingInfo({ ...data });
            },
          };
          console.log('a->', a);
          return (
            <ServerItem
              {...newItem}
              key={`${a.code}_${index}`}
              itemKey={`${a.code}_${index}1`}
            />
          );
        })}

        <div className="detial-page-place">
          <h3>取消预订规则</h3>
          <p>
            会议开始前可随时取消预订，或联系管理员取消；会议开始后，只能提前结束会议。
          </p>
        </div>

        <BootFooter />

        <div className="detial-page-footer">
          <div
            className="detial-page-footer-btn"
            onClick={(e) => {
              e.stopPropagation();
              this.handleSumit();
            }}
          >
            预订
          </div>
        </div>
        <Calendar
          type={"one"}
          locale={zhCN}
          defaultValue={[
            moment(bookingInfo.meetingDate).toDate(),
            moment(bookingInfo.meetingDate).toDate(),
          ]}
          visible={this.state.showCalendar}
          onCancel={() => {
            this.setState({ showCalendar: !this.state.showCalendar });
          }}
          onConfirm={(data: any) => {
            let selectDay = moment(data).format("YYYY-MM-DD");
            this.props.store.setBookingInfo({ meetingDate: selectDay });
            this.setState({ showCalendar: !this.state.showCalendar });
            appState.getRoomInfoDay(roomInfo.id, selectDay);
          }}
          minDate={moment().toDate()}
          maxDate={
            bookingLimitDays
              ? moment().add(bookingLimitDays, "days").toDate()
              : undefined
          }
        />
      </div>
    );
  }
}

export default (props: any) => {
  return (
    <Provider store={appState} userStatus={UserStatus}>
      <HomeDetial {...props} />
    </Provider>
  );
};

